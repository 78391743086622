<template>
    <div class="align-self-center">
        <div class="text-center">
            <p class="display-3 fw-bold lh-2">
                {{ $et("noticepage", "headline") }}
            </p>
            <p class="fs-4 py-2">
                {{ $et("noticepage", "description") }}
            </p>
        </div>
        <div class="d-flex justify-content-center">
            <base-button
                type="button"
                size="lg"
                class="w-50"
                data-test="next"
                @clicked="$router.push({ name: 'QuizPage' })"
            >
                {{ $et("noticepage", "btn_next") }}
            </base-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "NoticePage",
};
</script>